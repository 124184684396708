<template>
  <div class="layout-widget flex flex-col w-full h-full">
    <LayoutsWidgetHeader
      v-if="widgetSettings &&
      $route.path.includes('/widget/')"
    />
    <div class="flex flex-col md:flex-row w-full flex-grow overflow-hidden md:flex-grow bg-white">
      <LayoutsMainNav v-if="!minimized" />
      <slot />
    </div>
    <p
      v-if="$route.name === 'widget-businessCode-group'"
      class="flex items-center justify-center transition-color powered-by text-gray-800 text-xs bg-white"
    >
      <a href="https://www.converso.io" class="w-full text-center" target="_BLANK">Powered by Converso</a>
    </p>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const store = usePortalStore()
const widgetSettings = computed(() => store.getWidgetSettings)

const loggedInUser = computed(() => {
  return store.getLoggedInUser
})

const isAnonymouslyLoggedIn = computed(() => {
  return loggedInUser.value?.name === 'Anonymous'
})

const isLoggedIn = computed(() => {
  return !!store.getLoggedInUser
})

const minimized = computed(() => {
  if (
    route.name.includes('chat') ||
    route.name.includes('conversations') ||
    route.name.includes('payments') ||
    route.name.includes('invoice') ||
    route.name.includes('documents') ||
    !store.getConversations?.length
  ) {
    return true
  }
  return false
})
</script>

<style lang="scss">

.powered-by {
  transition: 0.3s color;
  &:hover {
    color: blue;
    color: v-bind('store.getWidgetSettings?.backgroundColour');
  }
}
</style>
